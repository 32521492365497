import { useState } from 'react';

import { BlogCard } from '@components/Blog/BlogCard';
import { Button, ButtonType } from '@components/Button';
import { BlogTileData } from '@interfaces/Blog';

export function BlogCards({ blogs }: { blogs: BlogTileData[] }) {
  const [showCount, setShowCount] = useState(3);
  const handleClick = () => {
    setShowCount(showCount + 3);
  };

  return (
    <>
      <section className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {blogs.slice(0, showCount).map((blog) => (
          <BlogCard blog={blog} key={blog.slug} />
        ))}
      </section>
      {showCount < blogs.length && (
        <Button
          type={ButtonType.button}
          onClick={handleClick}
          className="mx-auto my-5 w-fit"
        >
          More
        </Button>
      )}
    </>
  );
}
