/**
 * This component is for handling the quantity section on product pages
 * It displays the current quantity and has up and down chevrons to
 * increment or decrement the quanity
 */

import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import React, { ReactElement, ReactNode, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import { ErrorAlert } from '@components/Alerts/ErrorAlert';
import { Button } from '@components/Button';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';

export function Quantity({
  quantity,
  setQuantity,
  min = 1,
  max,
  inStock,
}: {
  quantity: number;
  setQuantity: React.Dispatch<React.SetStateAction<number>>;
  min?: number;
  max?: number;
  inStock?: boolean;
}): ReactElement {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ReactNode>();

  const handleDecrement = (changeEvent) => {
    if (quantity > min && quantity > 1) {
      setQuantity(quantity - 1);
    } else {
      setQuantity(Math.max(min, 1));
    }
  };
  const handleIncrement = (changeEvent) => {
    if (quantity === max) {
      // Can't have more
      setErrorMessage(
        <Paragraph>
          You can only purchase a maximum of {max} of this product per order
        </Paragraph>
      );
      setError(true);
    } else {
      setQuantity(quantity + 1);
    }
  };

  return inStock ? (
    <div>
      <h4 className="text-base font-bold text-black dark:text-white">
        Quantity
      </h4>
      <div className="m-0 flex items-center border border-solid border-grey-mid bg-grey-light dark:border-grey-dark dark:bg-grey-dark">
        {error && (
          <ErrorAlert show={error} setShow={setError}>
            {errorMessage}
          </ErrorAlert>
        )}
        <Button
          onClick={handleDecrement}
          buttonStyle="tertiary"
          aria-label="Decrease quantity by 1"
          icon={<FiChevronDown />}
        />
        <VisuallyHidden.Root asChild>
          <label htmlFor="quantity">Quantity selected</label>
        </VisuallyHidden.Root>

        <span
          id="quantity"
          className="h-full w-fit border-none px-2 text-center text-black dark:text-white"
        >
          {quantity}
        </span>

        <Button
          onClick={handleIncrement}
          buttonStyle="tertiary"
          aria-label="Increase quantity by 1"
          icon={<FiChevronUp />}
        />
      </div>
    </div>
  ) : (
    <></>
  );
}
