export function Availability({ availability }: { availability: string }) {
  return (
    <div className="block">
      <h4 className="pt-3 text-base font-bold text-black dark:text-white">
        Availability
      </h4>
      <span className="text-sm">
        {availability.length === 0 ? <>&nbsp;</> : availability}
      </span>
    </div>
  );
}
