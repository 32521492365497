import dynamic from 'next/dynamic';
import { useState } from 'react';
import { FiInfo } from 'react-icons/fi';

import { Button } from '@components/Button';
import { CustomImage } from '@components/Image';
import { TextLink } from '@components/Typography/TextLink/TextLink';

const Modal = dynamic(() =>
  import('../Modal/Modal').then((mod) => mod.ModalNoPortal)
);

/**
 * Displays the payments options (afterpay, zip, paypayl in 4) that
 * can be used when making a purchase over $100.
 *
 * @returns
 */
export function PaymentOptions() {
  const [showPayLaterModal, setShowPayLaterModal] = useState(false);

  return (
    <p className="text-sm">
      Pay in four instalments for orders over $100{' '}
      <Button
        onClick={(e) => setShowPayLaterModal(true)}
        buttonStyle="tertiary"
        icon={<FiInfo />}
        className="inline"
      />
      {showPayLaterModal && (
        <Modal show={showPayLaterModal} setShow={setShowPayLaterModal}>
          <TextLink href="/payments">
            <CustomImage
              src="/images/pay-in-four-easy-payments-when-shopping-at-bulk-nutrients.webp"
              width={640}
              alt="Pay in four fortnightly installments when your order is over $100"
              title="Pay in four fortnightly installments when your order is over $100"
            />
          </TextLink>
        </Modal>
      )}
    </p>
  );
}
