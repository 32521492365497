import dynamic from 'next/dynamic';
import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { ErrorAlert } from '@components/Alerts/ErrorAlert';
import { Button, ButtonType } from '@components/Button';
import { ButtonStyle } from '@components/Button/Button';
import { Fieldset } from '@components/PortableText/Form';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { TextLink } from '@components/Typography/TextLink/TextLink';
import { CustomForm } from '@interfaces/IForms';

const Modal = dynamic(() =>
  import('../Modal/Modal').then((mod) => mod.ModalNoPortal)
);

export function SuggestAFlavour({
  form,
  productName,
  productId,
}: {
  form: CustomForm;
  productName: string;
  productId: number;
}) {
  const [showModal, setShowModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const rhfMethods = useForm<any>({
    mode: 'onTouched',
  });

  const onSubmit: SubmitHandler<any> = async (data) => {
    setSubmitting(true);
    setSubmitted(false);
    const response = await fetch('/api/form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
      body: JSON.stringify({
        formId: form._id,
        formData: data,
      }),
    });
    if (response.ok) {
      setSubmitted(true);
      setTimeout(() => {
        setSubmitted(false);
      }, 3000);
    } else {
      setSubmitError(true);
    }
    setSubmitting(false);
    rhfMethods.reset();
  };

  return (
    <div>
      <Button
        onClick={(e) => setShowModal(true)}
        buttonStyle={ButtonStyle.text}
      >
        Suggest a flavour
      </Button>
      {showModal && (
        <Modal
          show={showModal}
          setShow={setShowModal}
          className="dark:bg-black"
        >
          <div>
            <h1>Suggest a Flavour</h1>
            <Paragraph className="text-grey-dark dark:text-white">
              Think your taste buds are an absolute 10/10?
            </Paragraph>
            <Paragraph className="text-grey-dark dark:text-white">
              Suggest a flavour for {productName} and we'll see if we can make
              it a reality!
            </Paragraph>
            <FormProvider {...rhfMethods}>
              <form
                onSubmit={rhfMethods.handleSubmit(onSubmit)}
                className="flex flex-col gap-5"
              >
                {form.fieldsets.map((fieldset) => (
                  <Fieldset key={fieldset._key} fieldset={fieldset} />
                ))}
                <input
                  {...rhfMethods.register('productId')}
                  type="hidden"
                  value={productId}
                  name="productId"
                />
                <Button type={ButtonType.submit} disabled={submitting}>
                  {submitting ? 'Submitting...' : 'Submit your suggestion'}
                </Button>
                <div
                  className={`${
                    submitted ? 'animate-[fadeInFromLeft_0.25s_forwards]' : ''
                  } -translate-x-full opacity-0`}
                >
                  Thanks! We've received your suggestion.
                </div>
                <ErrorAlert show={submitError} setShow={setSubmitError}>
                  <Paragraph>
                    Uh oh! Something went wrong trying to save that. Give it
                    another try, and{' '}
                    <TextLink href="/contact">get in touch</TextLink> if you
                    continue to have issues.
                  </Paragraph>
                </ErrorAlert>
              </form>
            </FormProvider>
          </div>
        </Modal>
      )}
    </div>
  );
}
