import { ScrollLink } from '@components/Typography/TextLink/ScrollLink';

import { StarRating } from './StarRating';

export function AverageRating({
  averageRating,
  reviews,
}: {
  averageRating: number;
  reviews: number;
}) {
  return (
    <>
      {averageRating > 0 ? (
        <p className="flex gap-1 py-2 text-sm">
          <StarRating rating={averageRating} />
          {averageRating} from {reviews}{' '}
          <ScrollLink id="Reviews">reviews</ScrollLink>
        </p>
      ) : null}
    </>
  );
}
