import { ReactElement, useContext } from 'react';

import {
  UIContext,
  UIContextInterface,
} from '@components/Context/UIContext/UIContext';
import { CustomImage } from '@components/Image';
import { USP } from '@interfaces/Sanity';

export function USPComponent({ usps }: { usps: USP[] }): ReactElement {
  const {
    displayDarkMode: [darkModeIsOn, setDarkModeIsOn],
  } = useContext<UIContextInterface>(UIContext);

  return (
    <div className="flex justify-between">
      {usps.map((usp, i) => (
        <div key={`usp-${i}`} className={i == 3 ? 'hidden tiny:block' : ''}>
          <CustomImage
            src={darkModeIsOn ? usp.imageDarkMode : usp.image}
            alt={usp.altText}
            width={80}
            height={80}
            usePlaceholder={false}
          />
          <span className="block max-w-[85px] overflow-hidden text-center text-xs font-bold uppercase">
            {usp.text}
          </span>
        </div>
      ))}
    </div>
  );
}
