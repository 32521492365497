import { BlogTileData } from './Blog';
import { CustomForm } from './IForms';
import { FAQ, SanityImage, USP } from './Sanity';

import type { PortableTextBlock } from '@portabletext/types';

export interface BulkPricingPrice {
  minimumQuantity: number;
  maximumQuantity: number | null;
  price: number;
}
export interface BulkPricingPercent {
  minimumQuantity: number;
  maximumQuantity: number | null;
  percentOff: number;
}
export interface BulkPricingAdjustment {
  minimumQuantity: number;
  maximumQuantity: number | null;
  priceAdjustment: number;
}

// Bulk Pricing Type Guards
export function isBulkPricingPrice(
  price: BulkPricingPrice | BulkPricingPercent | BulkPricingAdjustment
): price is BulkPricingPrice {
  return (price as BulkPricingPrice).price !== undefined;
}
export function isBulkPricingPercent(
  price: BulkPricingPrice | BulkPricingPercent | BulkPricingAdjustment
): price is BulkPricingPercent {
  return (price as BulkPricingPercent).percentOff !== undefined;
}

export interface Pricing {
  price: {
    value: number;
  };
  salePrice: {
    value: number;
  } | null;
  basePrice: {
    value: number;
  } | null;
  retailPrice: {
    value: number;
  } | null;
  mapPrice: {
    value: number;
  } | null;
  bulkPricing: (
    | BulkPricingPrice
    | BulkPricingPercent
    | BulkPricingAdjustment
  )[];
  priceRange: {
    min: {
      value: number;
      currencyCode: string;
    };
    max: {
      value: number;
      currencyCode: string;
    };
  };
}

interface VariantOption {
  displayName: string;
  label: string;
  optionId: number;
  valueId: number;
}

export interface Variant {
  availableFrom: string;
  availableFromBanter: string;
  availableFromLoggedIn: string;
  images: SanityImage[];
  options: VariantOption[];
  optionTags: string[];
  pricing: Pricing;
  productId: number;
  sku: string;
  variantId: number;
  flavourDescription: string | null;
}

export interface BaseSanityVariant {
  _createdAt: string;
  _id: string;
  _rev: string;
  _type: string;
  _updatedAt: string;
  productId: number;
  sku: string;
  variantId: number;
  optionTags: string[];
  scheduledDatetimeAll: string;
  scheduledDatetimeBanter: string;
  scheduledDatetimeLoggedIn: string;
  flavourDescription: string | null;
}
export interface SanityVariant extends BaseSanityVariant {
  options: {
    title: string;
    value: string;
  }[];
  images: SanityImage[];
}
export interface ProductOption {
  displayName: string;
  entityId: number;
  isRequired: boolean;
  checkedByDefault?: boolean;
  displayStyle?: string;
  values: {
    entityId: number;
    label: string;
    isDefault: boolean;
    hexColors?: string[];
    imageUrl?: string;
  }[];
}
export interface ProductDescription {
  _key: string;
  _type: string;
  contentDescription: string;
  contentBody?: (PortableTextBlock | FAQ | any)[];
  relatedBlogs?: BlogTileData[];
}

export interface SanityBaseProduct {
  averageRating: number;
  images: SanityImage[];
  price: number;
  entityId: number;
  shortDescription: string;
  shortName: string;
  slug: string;
  totalReviews: number;
  name: string;
  variants: SanityVariant[];
}
export interface SanityProduct extends SanityBaseProduct {
  _createdAt: string;
  _id: string;
  _rev: string;
  _type: string;
  _updatedAt: string;
  availability?: string;
  categories: string[];
  cta: SanityImage[];
  description: {
    _key: string;
    _type: string;
    contentDescription: string;
    contentBody: PortableTextBlock[];
  }[];
  defaultVariantId: number;
  freeSample: boolean;
  imgUrl: string;
  isFeatured: boolean;
  minPurchaseQty: number;
  maxPurchaseQty: number;
  optionSet: {
    options: {
      displayName: string;
      displayType: {
        _key: string;
        _type: string;
        displayStyle: string;
        values: string[];
      }[];
      entityId?: number;
    }[];
  };
  packageDescription: string;
  pricePerServe: number | null;
  servingSize: number;
  shortTitle: string;
  sku: string;
  sortOrder: number;
  spritePosition: string;
  starRatings: {
    title: string;
    value: number;
  }[];
  suggestFlavours: boolean;
  tgaStatement?: string;
  theme: string;
  title: string;
  toggles: {
    title: string;
    value: boolean;
  }[];
  totalSold: number;
  usp?: USP[];
  referenceProduct?: { entityId: number; name: string; shortName: string };
  tiktokMetaTitle?: string;
  tiktokMetaDescription?: string;
}

export interface RelatedProduct {
  entityId: number;
  name: string;
  slug: string;
}

export interface BaseProduct {
  averageRating: number;
  defaultVariantId: number;
  entityId: number; // I think the name comes from graphQL BC. Their REST is just id
  images: SanityImage[];
  maxPurchaseQty: number; // default is 9999
  minPurchaseQty: number; // default is 1
  name: string;
  options: ProductOption[]; // straight from BC
  pricePerServe: number | null;
  pricing: Pricing;
  servingSize: number;
  shortDescription: string;
  shortName: string | null;
  sku: string;
  slug: string;
  tgaStatement?: string;
  totalReviews: number;
  variants: Variant[]; // come from sanity with options from BC
}

export const isSuggestFlavoursForm = (
  item: boolean | CustomForm
): item is CustomForm => typeof item !== 'boolean';

export const isFullProduct = (
  product: BaseProduct | Product
): product is Product => product.hasOwnProperty('description');

export interface Product extends BaseProduct {
  _id: string;
  availability?: string;
  cta?: SanityImage[];
  defaultImgUrl: string;
  description: ProductDescription[];
  freeSampleAvailable: boolean;
  packageDescription: string;
  serverSideStock?: boolean;
  starRatings: {
    title: string;
    value: number;
  }[];
  suggestFlavours: boolean | CustomForm;
  theme: string;
  toggles: {
    title: string;
    value: boolean;
  }[];
  usp?: USP[];
  referenceProduct?: { entityId: number; name: string; shortName: string };
  tiktokMetaTitle?: string;
  tiktokMetaDescription?: string;
}

export interface AlgoliaProductCategory {
  _id: string;
  bigCommerceId: number;
  title: string;
  parent: null | AlgoliaProductCategory;
}
export interface AlgoliaProduct extends BaseProduct {
  __autocomplete_queryID: string;
  __position: number;
  __queryID?: string;
  _highlightResult: unknown;
  _updatedAt: string;
  'categories.lvl0': string[];
  'categories.lvl1': string[];
  'categories.lvl2': string[];
  categories: AlgoliaProductCategory[];
  hideFromSearch: boolean;
  ingredients: string;
  isFeatured: boolean;
  objectID: string;
  sortOrder: number;
  toggles: { title: string; value: string }[];
  totalReviews: number;
  totalSold: number;
}

export interface S3LabResult {
  id: number;
  name: string;
  url: string;
  date: string;
  formattedDate: string;
  flavour: string;
  type: string;
  dateGroup?: string;
}

// TODO: remove in a few months
export interface ProductArchived extends BaseProduct {
  availability?: string;
  cta?: SanityImage[];
  defaultImgUrl: string;
  defaultVariantId: number;
  description: ProductDescription[];
  freeSampleAvailable: boolean;
  options: ProductOption[];
  packageDescription: string;
  servingSize: number;
  reviews: number;
  serverSideStock?: boolean;
  starRatings: {
    title: string;
    value: number;
  }[];
  theme: string;
  toggles: {
    title: string;
    value: boolean;
  }[];
  usp?: USP[];
  url: string;
  referenceProduct?: { entityId: number; name: string; shortName: string };
}

export function isArchivedProduct(
  product: Product | ProductArchived
): product is ProductArchived {
  return (product as ProductArchived).url !== undefined;
}

// used in Homepage Carousel
export interface CarouselItemReferencedProduct {
  id: string;
  productId: number;
  sku: string;
  defaultVariant: {
    variantId: number;
  };
  slug: string;
  type: string;
}

export interface CarouselItemReferencedProductVariant {
  productId: number;
  sku: string;
  variantId: number;
}
