import { useContext, useEffect } from 'react';

import {
  UIContext,
  UIContextInterface,
} from '@components/Context/UIContext/UIContext';
import { LocalStorageProduct } from '@interfaces/LocalStorage';
import { Product } from '@interfaces/Product';
import {
  addIndexedDBData,
  getIndexedDBStoreData,
  Stores,
  updateIndexedDBData,
} from '@lib/localData';
import { logBreadcrumb } from '@lib/utils';

export function RecentlyViewedProducts({ product }: { product: Product }) {
  const {
    recentlyViewed: { updatedRecentlyViewed },
  } = useContext<UIContextInterface>(UIContext);

  const setRecentlyViewedProduct = async (storeInfo) => {
    // eslint-disable-next-line no-void
    try {
      const current = await getIndexedDBStoreData<LocalStorageProduct[]>(
        Stores.keyvaluepairs,
        'recentlyViewed'
      );

      if (current) {
        let modified: LocalStorageProduct[];
        const existing = current.findIndex(
          (item) => item.product.entityId === product.entityId
        );

        // Set new store data
        if (existing > -1) {
          // already in there, update time
          current[existing] = storeInfo;
          modified = [...current];
        } else {
          modified = [...current, storeInfo];
        }

        // Newest view at the top
        modified.sort((a, b) => (a.viewedTime < b.viewedTime ? 1 : -1));

        // trim the store to most recent 10
        if (modified.length > 10) {
          modified.splice(10);
        }
        // eslint-disable-next-line no-void
        await updateIndexedDBData(
          Stores.keyvaluepairs,
          'recentlyViewed',
          modified
        );
        updatedRecentlyViewed();
      } else {
        // eslint-disable-next-line no-void
        await addIndexedDBData(Stores.keyvaluepairs, 'recentlyViewed', [
          storeInfo,
        ]);
        updatedRecentlyViewed();
      }
    } catch (err) {
      logBreadcrumb({
        category: 'IndexedDB',
        message: `Tried to store item in recently viewed`,
        level: 'error',
      });
    }
  };

  useEffect(() => {
    const storeInfo = {
      product,
      viewedTime: Date.now(),
    };
    setRecentlyViewedProduct(storeInfo);
  }, [product]);

  return <></>;
}
