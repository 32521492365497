import { useFormContext } from 'react-hook-form';

import { Select } from '@components/FormComponents';
import { ProductOption, Variant } from '@interfaces/Product';

const tagMap = {
  limitedEdition: 'Limited Edition',
  hasta: 'HASTA Certified',
};

export function Options({
  options,
  variants,
  stockLevels,
}: {
  options: ProductOption[];
  variants: Variant[];
  stockLevels: Record<number, boolean> | undefined;
}) {
  const rhfMethods = useFormContext();
  // We want to go off variant options, because they have the special tags, or limited to groups
  /**
   * Check whether the option is in stock (or could be if other options not selected)
   * @param optionId the entityId of the option (e.g. for flavour WPI or whatever)
   * @param valueId the entityId of the value (e.g. for Chocolate)
   * @returns boolean
   */
  const checkInStock = (optionId: number, valueId: number) => {
    // all variants of this value
    // (e.g. we have selected 250g and want all 250g regardless of flavour)
    const possibleVariants = variants.filter((pv) => {
      if (!pv.options) return false;
      return (
        pv.options.filter(
          (o) => o.optionId === optionId && o.valueId === valueId
        ).length > 0
      );
    });

    /*
      Have I selected other limiting options? else just am I in stock for any option?
      1. check what we have already selected
      2. filter out the thing we are looking at (e.g. flavour) because we are gonna change it
      3. only want variants where optionId of productOption key is there, regardless of valueId
    */
    const limitedVariants = [] as Variant[];
    const currentValues = rhfMethods.getValues();
    Object.keys(currentValues).forEach((key) => {
      if (key !== optionId.toString()) {
        limitedVariants.push(
          ...possibleVariants.filter(
            (pv) =>
              pv.options.map((o) => o.optionId.toString()).includes(key) &&
              pv.options.map((o) => o.valueId).includes(currentValues[key])
          )
        );
      }
    });

    if (limitedVariants.length === 0 && stockLevels)
      return possibleVariants.reduce(
        (acc, cur) => acc || (stockLevels && stockLevels[cur.variantId]),
        false
      );

    // in stock if at least some variant of it in stock (e.g. 250g lemonade, but not 1kg is in stock lemonade)
    return limitedVariants.reduce(
      (acc, cur) =>
        acc || (stockLevels !== undefined && stockLevels[cur.variantId]),
      false
    );
  };

  return (
    <form>
      {/* Input for each option (e.g. flavour and size) */}
      {options.map((option) => {
        if (option.values.length === 1) {
          return (
            <h4
              className="py-3 text-base font-bold text-black dark:text-white"
              key={option.entityId}
            >
              {option.displayName} - {option.values[0].label}
            </h4>
          );
        } else {
          return (
            <Select
              key={option.entityId}
              name={option.entityId.toString()}
              label={option.displayName}
              labelclasses="pt-3 text-base font-bold text-black dark:text-white"
              options={variants.reduce(
                (acc, variant) => {
                  // Get variants that include this option
                  const variantOption = variant.options.filter(
                    (o) => o.optionId === option.entityId
                  )[0];

                  if (acc.some((o) => o.value === variantOption?.valueId)) {
                    return acc;
                  }

                  if (!variantOption) return acc;

                  const inStock = checkInStock(
                    option.entityId,
                    variantOption.valueId
                  );
                  // We can't add anything but text to options and can't really style
                  const optionTag = variant.optionTags[0];
                  let label = variantOption.label;
                  if (!inStock) {
                    label += ' - Back in Stock Soon';
                  } else if (tagMap[optionTag]) {
                    label += ` - ${tagMap[optionTag]}`;
                  }

                  acc.push({
                    value: variantOption.valueId,
                    label: label,
                    disabled: !inStock,
                  });
                  return acc;
                },
                [] as Array<{ value: number; label: string; disabled: boolean }>
              )}
            />
          );
        }
      })}
    </form>
  );
}
