import ImageGallery from 'react-image-gallery';

import { BulkErrorBoundary } from '@components/Alerts/ErrorBoundaryFallback';
import { CustomImage } from '@components/Image';
import { getImageSrcUrl } from '@components/Image/Image';
import { useWindowSize } from '@hooks/useWindowSize';
import { SanityImage } from '@interfaces/Sanity';

export function ImageGallerySection({ images }: { images: SanityImage[] }) {
  const { width } = useWindowSize();
  // fixes improperly sized images flag in LH
  const smallWidthToUse = width && width <= 600 ? 500 : 750;

  return (
    <BulkErrorBoundary location="Product/ImageGallery">
      {width && width > 1400 ? (
        <div
          className="grid h-fit grid-cols-2 gap-2 
        [&>figure:first-child:has(~_*:hover)]:opacity-50 
        [&>figure:first-child>img]:opacity-100
        [&>figure:first-child_>_.overlay]:opacity-100 [&_.overlay]:opacity-50 [&_img:hover_~_.overlay]:opacity-100"
        >
          {images.map((image) => (
            <CustomImage
              key={image._key}
              image={image}
              width={500}
              height={500}
              className="opacity-50 transition duration-200 hover:opacity-100"
              showOverlay
            />
          ))}
        </div>
      ) : (
        <ImageGallery
          additionalClass="image-gallery"
          items={images.map((image) => ({
            original: getImageSrcUrl({
              image,
              width: smallWidthToUse,
              height: smallWidthToUse,
            }) as string,
            image,
          }))}
          renderItem={(image: { original: string; image: SanityImage }) => (
            <div className="min-h-[200px]">
              <CustomImage
                key={image.image._key}
                image={image.image}
                width={smallWidthToUse}
                height={smallWidthToUse}
                showOverlay
                priority={true}
                sizes={`${smallWidthToUse}px`}
              />
            </div>
          )}
          showPlayButton={false}
          useBrowserFullscreen={false}
          showThumbnails={false}
        />
      )}
    </BulkErrorBoundary>
  );
}
