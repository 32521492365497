export function PurchaseLimits({ min, max }: { min: number; max: number }) {
  return (
    <>
      {!!min && min > 1 && (
        <div>
          <h4 className="pt-3 text-base font-bold text-black dark:text-white">
            Minimum Purchase
          </h4>
          <span className="text-base">{min}</span>
        </div>
      )}
      {!!max && max < 9999 && (
        <div>
          <h4 className="pt-3 text-base font-bold text-black dark:text-white">
            Maximum Purchase
          </h4>
          <span className="text-base">{max}</span>
        </div>
      )}
    </>
  );
}
