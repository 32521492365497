import React, { ReactElement, useState } from 'react';

import { Button } from '@components/Button';
import { TextLink } from '@components/Typography/TextLink/TextLink';
import { S3LabResult } from '@interfaces/Product';

// this could probably go into constants somewhere. Not a big deal, given it's only used here at the moment
const BASE_S3_URL = 'https://pdfs.bulknutrients.com.au/';

/**
 * Receives lab results from S3 and render a list
 * @param items as an array of S3LabResult[]
 * @returns ReactElement
 */
export function LabResultList({
  items,
}: {
  items: S3LabResult[];
}): ReactElement {
  // this is min of 1 and max of 3 columns based on the number of items (10 items per column)
  const columns = Math.min(Math.max(Math.ceil(items.length / 10), 1), 3);
  const [displayNumber, setDisplayNumber] = useState(10 * columns);

  const getName = (lab: S3LabResult) => {
    const flavour = lab.flavour ? ` - ${lab.flavour}` : '';

    if (lab.type === 'hasta') {
      return (
        <>
          {lab.formattedDate} - HASTA Certification{' '}
          <span className="whitespace-normal sm:whitespace-nowrap">
            ({lab.name}
            {flavour})
          </span>
        </>
      );
    }
    return `${lab.formattedDate} - ${lab.name} ${flavour}`;
  };

  return (
    <div>
      <ul
        className="m-0 mb-5 ml-5 list-disc columns-[--max-column-count] p-0 pl-5"
        style={
          {
            '--max-column-count': columns,
          } as React.CSSProperties
        }
      >
        {items.slice(0, displayNumber).map((c, idx) => (
          <li key={idx} className="my-1">
            <TextLink
              id={`${c.date}-${c.type}-${c.id}${
                c.flavour ? `-${c.flavour}` : ''
              }`}
              target={'_blank'}
              rel={'noopener noreferrer'}
              href={`${BASE_S3_URL}${c.url}`}
            >
              {getName(c)}
            </TextLink>
          </li>
        ))}
      </ul>
      {items.length > displayNumber && (
        <div className="flex justify-center">
          <Button onClick={(e) => setDisplayNumber(displayNumber + 20)}>
            More
          </Button>
        </div>
      )}
    </div>
  );
}
